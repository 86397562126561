import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import ResourceTracker from "~/components/threejsInit/trackResource";
import { RGBELoader } from "three/addons/loaders/RGBELoader.js";
import { HDRCubeTextureLoader } from "three/addons/loaders/HDRCubeTextureLoader.js";

let resMgr = new ResourceTracker();
const track = resMgr.track.bind(resMgr);
export default class threejsInit {
  constructor(props) {
    this.scene = new THREE.Scene();
    this.camera = null;
    this.renderer = new THREE.WebGLRenderer(
      //增加下面两个属性，可以抗锯齿
      {
        antialias: true,
        alpha: true,
        logarithmicDepthBuffer: true,
      }
    );
    // this.renderer.setClearColor(0x000000, 1.0)
    this.scene.background = null;

    // this.scene.environment.mapping = THREE.EquirectangularReflectionMapping;
    this.labelRenderer = new CSS2DRenderer();
    this.controls = null;
    this.raycaster = new THREE.Raycaster();
    this.pointer = new THREE.Vector2();
    this.canvas = props.ref;
    this.selectedObj = null;
    props.labelDiv && (this.labelDiv = props.labelDiv.current);
    this.width = this.canvas.current.clientWidth;
    this.height = this.canvas.current.clientHeight;
    this.near = 0;
    window.addEventListener("resize", this.onWindowResize);
    this.clock = new THREE.Clock();
    this.time = { value: 0 };
    this.isStart = true;
    this.timer = {};

    //控制帧频率
    // this.clock = THREE.Clock();//计时器
    this.FPS = 30; // 指的是 30帧每秒的情况
    this.singleFrameTime = 1 / this.FPS;
    this.timeStamp = 0;
  }

  init = () => {
    this.initCamera();
    this.initLight();
    this.initControls();
    this.initRenderer();
    this.animate();
  };
  addEnvLight = () => {
    this.scene.environment = new RGBELoader().load(
      "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/hts/harvest_1k.hdr",
      (texture) => {
        const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        pmremGenerator.compileEquirectangularShader();
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        // 设备为背景（也可以用其他的的场景背景）
        // this.scene.background = envMap;
        // 设为场景中所有物理材质的环境贴图
        this.scene.environment = envMap;
        texture.dispose();
        pmremGenerator.dispose();
      },
      undefined,
      (error) => {
        console.error("Error loading HDR texture", error);
      }
    );
  };
  delete = () => {
    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.labelRendere = null;
    this.pointer = null;
    this.canvas = null;
    this.selectedObj = null;
    this.labelDiv = null;
    this.width = null;
    this.near = null;
    this.height = null;
    this.clock = null;
    this.isStart = null;
    this.timer = null;
  };

  destrory = () => {
    try {
      this.renderer?.dispose();
      this.renderer?.forceContextLoss();
      this.renderer.content = null;
      let gl = this.renderer.domElement.getContext("webgl");
      if (gl && gl.getExtension("WEBGL_lose_context")) {
        gl.getExtension("WEBGL_lose_context").loseContext();
      }
      this.scene.traverse((child) => {
        // console.log(child);
        if (child.material) {
          child.material.dispose();
        }
        if (child.geometry) {
          child.geometry.dispose();
        }
        child = null;
      });
      // console.log("scene--------------------", this.scene);
      cancelAnimationFrame(this.timer["animate"]);
      this.scene = null;
      // console.log("场景内存-----", this.renderer.info)
      this.renderer = null;
      this.delete();
      window.removeEventListener("resize", this.onWindowResize);

      resMgr && resMgr.dispose();
      // console.log(this.scene);
    } catch (e) {
      console.error("Failed to destroy threejs", e);
    }
  };

  addLabelBox = () => {
    const moonDiv = document.createElement("div");
    moonDiv.style.cssText =
      "width: 100px;padding: 10px;color: #fff;font-size: 14px;background-color: rgba(20, 143, 211, 0.68);border: 1px solid rgba(127, 177, 255, 0.75);";

    moonDiv.textContent = "配置";
    moonDiv.style.marginTop = "-1em";
    const moonLabel = new CSS2DObject(moonDiv);
    moonLabel.position.set(0, 0, 0);
    // box.add(moonLabel);
    moonLabel.layers.set(0);

    return moonLabel;
  };

  initRenderer = () => {
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.BasicShadowMap;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    // this.renderer.shadowMap.enable = true
    this.renderer.sortObjects = true; //不再透明度排序
    this.canvas.current.appendChild(this.renderer.domElement);
    this.labelRenderer.setSize(this.width, this.height);
    this.labelRenderer.domElement.style.position = "absolute";
    this.labelRenderer.domElement.style.top = "0px";
    // let renderer2 = new CSS3DRenderer();
    // renderer2.setSize(window.innerWidth, window.innerHeight);
    // document.getElementById('container').appendChild(renderer2.domElement);
    this.canvas.current.appendChild(this.labelRenderer.domElement);
  };

  initControls = () => {
    this.controls = new OrbitControls(
      this.camera,
      this.labelRenderer.domElement
    );
    this.controls.enableDamping = true; //阻尼
    this.controls.enableZoom = true; //缩放
    this.controls.autoRotate = false; //自动旋转
    this.controls.minDistance = 0;
    this.controls.maxDistance = 900;
    this.controls.enablePan = true;
  };
  initCamera = () => {
    this.camera = new THREE.PerspectiveCamera(
      50,
      this.width / this.height,
      0.1,
      1000
    );
    this.camera.position.set(0.784, 10, 25);
    this.scene.add(track(this.camera));
  };
  initLight = () => {
    // let ambientLight = new THREE.AmbientLight(0x7c7c7c, 3.0);

    // let light = new THREE.DirectionalLight(0xFFFFFF, 3.0);
    // light.position.set(0.32, 0.39, 0.7);
    // this.scene.add(light)
    let dirLight = new THREE.DirectionalLight(0xffffff, 1);
    dirLight.name = "Dir. Light";
    dirLight.position.set(20, 40, 10);
    dirLight.castShadow = true;
    dirLight.shadow.camera.near = 1;
    dirLight.shadow.camera.far = 100;
    dirLight.shadow.camera.right = 150;
    dirLight.shadow.camera.left = -150;
    dirLight.shadow.camera.top = 150;
    dirLight.shadow.camera.bottom = -150;
    dirLight.shadow.mapSize.width = 1024;
    dirLight.shadow.mapSize.height = 1024;
    dirLight.rotateY = Math.PI / 2;
    this.scene.add(track(dirLight));
  };
  animate = () => {
    this.timer["animate"] = requestAnimationFrame(this.animate);

    const delta = this.clock.getDelta(); //获取距离上次请求渲染的时间
    this.timeStamp += delta;
    if (this.timeStamp > this.singleFrameTime) {
      this.renderer.render(this.scene, this.camera);
      this.labelRenderer.render(this.scene, this.camera);
      this.controls.update();
      // 剩余的时间合并进入下次的判断计算 这里使用取余数是因为 当页页面失去焦点又重新获得焦点的时候，delta数值会非常大， 这个时候就需要
      this.timeStamp = this.timeStamp % this.singleFrameTime;
    }
  };

  onPointerClick = (event, type, currentCanvas) => {
    let tempCanvas = currentCanvas ? currentCanvas : this.canvas.current;
    this.controls.autoRotate = false;
    this.isStart = !this.isStart;
    this.pointer.x =
      ((event.clientX - tempCanvas.getBoundingClientRect().left) /
        tempCanvas.clientWidth) *
        2 -
      1;
    this.pointer.y =
      -(
        (event.clientY - tempCanvas.getBoundingClientRect().top) /
        tempCanvas.clientHeight
      ) *
        2 +
      1;

    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children);
    if (intersects.length > 0) {
      const selectedObj = intersects[0].object;
      const { parent } = intersects[0].object;
      // console.log(intersects[0].point);
      if (selectedObj.name === "massSpectrometer") {
        return selectedObj.parent.parent.parent.parent.cfObj;
      }
      if (
        type === "pip" &&
        (parent.cfType === "model" || selectedObj.cfType === "model")
      ) {
        // console.log(selectedObj.parent);
        let target = selectedObj;

        if (parent.type !== "Scene") {
          target = parent;
        }
        return target.cfObj || selectedObj.cfObj;
      } else if (type === "ML") {
        this.selectedObj = parent.parent;
        return selectedObj;
      } else if (type === "treeRoot") {
        let clickObj = {
          point: intersects[0].point,
          intersects,
          selectedObj: selectedObj,
        };
        return clickObj;
      } else if ((type = "zzt3")) {
        let clickObj = intersects[0].object;
        // intersects.forEach((item) => {
        //   if (!item.notClick) {
        //     clickObj = item;
        //     return;
        //   }
        // });
        if (intersects[0].object.notClick) {
          clickObj = intersects[1].object;
        }
        return clickObj;
      }
    } else return null;
  };

  onWindowResize = () => {
    if (this.canvas?.current) {
      const { clientWidth, clientHeight } = this.canvas.current;
      // console.log("🌲🌲🌲🌲", clientWidth, clientHeight);
      this.camera.aspect = clientWidth / clientHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(clientWidth, clientHeight);
      this.labelRenderer.setSize(clientWidth, clientHeight);
    }
  };

  // 创建一个地面,用来接收正方体的阴影
  addGround(type) {
    if (type == "eq01") {
      const geometry = new THREE.PlaneGeometry(1000, 1000);
      const material = new THREE.MeshPhongMaterial({
        color: 0xe9e9e9,
        depthWrite: false,
      });

      const ground = new THREE.Mesh(geometry, material);
      ground.position.set(0, -100, 0);
      ground.rotation.x = -Math.PI / 2;
      ground.receiveShadow = true;
      this.scene.add(track(ground));

      const grid = new THREE.GridHelper(1000, 50, 0x000000, 0x000000);
      grid.position.y = -100;
      grid.material.opacity = 0.2;
      grid.material.transparent = true;
      this.scene.add(track(grid));
    } else {
      const geometry = new THREE.PlaneGeometry(500, 500);
      const material = new THREE.MeshPhongMaterial({
        color: 0xe9e9e9,
        depthWrite: false,
      });

      const ground = new THREE.Mesh(geometry, material);
      ground.position.set(0, -5, 0);
      ground.rotation.x = -Math.PI / 2;
      ground.receiveShadow = true;
      this.scene.add(track(ground));

      const grid = new THREE.GridHelper(500, 100, 0x000000, 0x000000);
      grid.position.y = -5;
      grid.material.opacity = 0.2;
      grid.material.transparent = true;
      this.scene.add(track(grid));
    }
  }

  newGround() {
    const geometry = new THREE.PlaneGeometry(500, 500);
    const material = new THREE.MeshPhongMaterial({
      color: "rgb(247, 247, 247)",
      depthWrite: false,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.4,
    });

    const ground = new THREE.Mesh(geometry, material);
    ground.position.set(0, -3 - 0.1, 0);
    ground.rotation.x = -Math.PI / 2;
    ground.receiveShadow = true;
    ground.name = "ground";
    return ground;
  }
}
